import * as THREE from "three";
import { CustomLine } from "./CustomLine";
import { useMemo, useRef } from "react";
import { useFrame } from "@react-three/fiber";

export const Polyline = ({
  points,
  stroke,
  strokeWidth = 2,
  opacity = 1,
  design_object = null,
  strokeScaleEnabled = true,
  ...props
}) => {
  return (
    <CustomLine
      points={points}
      stroke={stroke}
      strokeWidth={strokeWidth}
      opacity={opacity}
      strokeScaleEnabled={strokeScaleEnabled}
      {...props}
    />
  );
};

export const Polygon = ({
  points,
  stroke,
  fill,
  strokeWidth = 2,
  opacity = 1,
  design_object = null,
  ...props
}) => {
  props.design_object = design_object;

  const parsedPoints = [];
  for (let i = 0; i < points.length; i += 2) {
    parsedPoints.push([points[i], points[i + 1]]);
  }

  // Create the shape from the parsed points
  const shape = new THREE.Shape();
  parsedPoints.forEach(([x, y], index) => {
    if (index === 0) {
      shape.moveTo(x, y); // Start from the first point
    } else {
      shape.lineTo(x, y); // Draw lines to subsequent points
    }
  });
  shape.closePath(); // Ensure the shape is closed

  return (
    <Group {...props}>
      {/* Filled Shape */}
      <mesh>
        <shapeGeometry args={[shape]} />
        <meshBasicMaterial
          color={fill}
          side={THREE.DoubleSide}
          transparent={fill ? opacity < 1 : true}
          opacity={fill ? opacity : 0}
        />
      </mesh>
      <Polyline
        points={points}
        stroke={stroke}
        strokeWidth={strokeWidth}
        opacity={stroke ? opacity : 0}
        design_object={design_object}
        {...props}
      />
    </Group>
  );
};

export const Circle = ({
  x = 0,
  y = 0,
  radius = 10,
  segments = 32,
  scaleX = 1,
  scaleY = 1,
  stroke,
  fill,
  strokeWidth = 2,
  opacity = 1,
  design_object = null,
  ...props
}) => {
  return (
    <RegularPolygon
      sides={segments}
      x={x}
      y={y}
      radius={radius}
      scaleX={scaleX}
      scaleY={scaleY}
      stroke={stroke}
      fill={fill}
      strokeWidth={strokeWidth}
      opacity={opacity}
      design_object={design_object}
      {...props}
    />
  );
};

export const RegularPolygon = ({
  sides = 3,
  x = 0,
  y = 0,
  radius = 10,
  rotation = 0,
  scaleX = 1,
  scaleY = 1,
  stroke,
  fill,
  strokeWidth = 2,
  opacity = 1,
  design_object = null,
  strokeScaleEnabled = true,
  ...props
}) => {
  const meshRef = useRef();

  useFrame(() => {
    if (meshRef.current) {
      // 스케일 대신 원의 절대 크기를 유지
      /* @ts-ignore */
      meshRef.current.geometry.parameters.radius = radius;
      /* @ts-ignore */
      meshRef.current.geometry.attributes.position.needsUpdate = true;
    }
  });

  props.design_object = design_object;
  radius *= scaleX;

  // Generate polygon vertices
  const shape = new THREE.Shape();
  const points = [];
  for (let i = 0; i <= sides; i++) {
    const angle = (i / sides) * Math.PI * 2 + rotation * (Math.PI / 180);
    const vx = radius * Math.cos(angle);
    const vy = radius * Math.sin(angle);
    if (i === 0) {
      shape.moveTo(vx, vy);
    } else {
      shape.lineTo(vx, vy);
    }
    points.push(...[vx, vy]);
  }

  return (
    <Group {...props}>
      {/* Fill for the polygon */}
      <mesh ref={meshRef} position={[x, y, 0]}>
        <shapeGeometry args={[shape]} />
        <meshBasicMaterial
          color={fill}
          side={THREE.DoubleSide}
          transparent={fill ? opacity < 1 : true}
          opacity={fill ? opacity : 0}
        />
      </mesh>
      <Polyline
        points={points}
        stroke={stroke}
        strokeWidth={strokeWidth}
        position={[x, y, 0]}
        transparent={stroke ? opacity < 1 : true}
        opacity={stroke ? opacity : 0}
        strokeScaleEnabled={strokeScaleEnabled}
        options={{ closed: true }}
      />
    </Group>
  );
};

export const Group = ({ design_object = null, children, ...props }) => {
  props.design_object = design_object;
  return <group {...props}>{children}</group>;
};

export const OpenArc = ({
  x,
  y,
  radius,
  angle,
  angleStart,
  stroke,
  strokeWidth,
  rotation,
}: {
  x: number;
  y: number;
  radius: number;
  angle: number;
  angleStart?: number;
  stroke: string | CanvasGradient;
  strokeWidth: number;
  rotation?: number;
}) => {
  const points = useMemo(() => {
    const path = new THREE.Path();

    path.absarc(
      0,
      0,
      radius,
      ((angleStart ?? 0) * Math.PI) / 180 + Math.PI, // Start angle in radians
      (((angleStart ?? 0) + angle) * Math.PI) / 180 + Math.PI, // End angle in radians
      false
    );
    // console.log(path);
    // Convert path to points
    return path
      .getPoints(50)
      .map((p) => [p.x, p.y])
      .reduce((p, c) => [...p, ...c], []);
  }, [radius, angle, angleStart]);

  return (
    <Polyline
      points={points}
      position={[x, y, 0]}
      rotation={[0, 0, ((rotation ?? 0) * Math.PI) / 180]}
      stroke={stroke}
      strokeWidth={strokeWidth}
    />
  );
  // return (
  //   <Shape
  //     x={x}
  //     y={y}
  //     sceneFunc={(ctx, shape) => {
  //       // 열린 호를 그리는 메서드
  //       ctx.beginPath();
  //       ctx.arc(
  //         0,
  //         0,
  //         radius,
  //         ((angleStart ?? 0) * Math.PI) / 180,
  //         (((angleStart ?? 0) + angle) * Math.PI) / 180,
  //         false
  //       );
  //       ctx.strokeShape(shape); // 호를 스트로크로 그림
  //     }}
  //     stroke={stroke}
  //     strokeWidth={strokeWidth}
  //     offsetX={0}
  //     offsetY={0}
  //     rotation={rotation}
  //   />
  // );
};

// export const GeometryAsSVGPath = ({ data, stroke, strokeWidth, ...props }) => {
//   const pathData = useMemo(() => convertToSVGPath(data), [data]); // Line과 Arc 데이터를 결합한 경로

//   // Parse SVG path and generate points
//   const points = useMemo(() => {
//     if (!pathData) return [];
//     const parsedPath = parseSVG(pathData);

//     // Convert parsed commands to points
//     const pathPoints: [number, number, number][] = [];
//     parsedPath.forEach((command) => {
//       if ("x" in command && "y" in command) {
//         pathPoints.push([command.x, command.y, 0]);
//       }
//     });

//     return pathPoints;
//   }, [data]);

//   if (points.length === 0) return;

//   return (
//     <R3fLine
//       points={points} // Pass points to the Line component
//       stroke={stroke}
//       strokeWidth={strokeWidth}
//       {...props}
//     />
//   );

//   // const pathData = useMemo(() => convertToSVGPath(data), [data]); // Line과 Arc 데이터를 결합한 경로

//   // return (
//   //   <Path
//   //     data={pathData} // 변환된 SVG 경로
//   //     stroke={stroke}
//   //     strokeWidth={strokeWidth}
//   //     {...props}
//   //   />
//   // );
// };

export const GeometryAsSVGPath = ({ data, stroke, strokeWidth, ...props }) => {
  // return (
  //   <group>
  //     {data.map((e) =>
  //       e.type === "Line" ? (
  //         <R3fLine
  //           points={e.points} // Pass points to the Line component
  //           position={[e.x, e.y, 0]}
  //           rotation={[0, 0, (e.rotation * Math.PI) / 180]}
  //           stroke={stroke}
  //           strokeWidth={strokeWidth}
  //           scale={e.scaleX}
  //           {...props}
  //         />
  //       ) : e.type === "Arc" ? (
  //         <group
  //           position={[e.x, e.y, 0]}
  //           rotation={[0, 0, (e.rotation * Math.PI) / 180]}
  //           scale={e.scaleX}
  //         >
  //           <OpenArc
  //             x={e.u}
  //             y={e.v}
  //             radius={e.radius}
  //             angle={e.angle}
  //             angleStart={e.angleStart}
  //             stroke={stroke}
  //             strokeWidth={strokeWidth}
  //           />
  //         </group>
  //       ) : (
  //         <></>
  //       )
  //     )}
  //   </group>
  // );

  // const pathData = useMemo(() => convertToSVGPath(data), [data]); // Line과 Arc 데이터를 결합한 경로

  // console.log(pathData);
  return <></>;

  // return (
  //   <Path
  //     data={pathData} // 변환된 SVG 경로
  //     stroke={stroke}
  //     strokeWidth={strokeWidth}
  //     {...props}
  //   />
  // );
};
