export const translate = (geometry: number[], vector: number[]) => {
  const result = [];

  for (let i = 0; i < geometry.length; i += vector.length) {
    for (let j = 0; j < vector.length; j++) {
      result.push(geometry[i + j] + vector[j]);
    }
  }

  return result;
};

export const scaleVector = (vector: number[], scale: number) => {
  return vector.map((x) => x * scale);
};

export const unitVector = (segment: number[]) => {
  const [x1, y1, x2, y2] = segment;

  const vector = [x2 - x1, y2 - y1];
  const length = ((x1 - x2) ** 2 + (y1 - y2) ** 2) ** 0.5;

  return vector.map((x) => x / length);
};

export const rotateVector = (vector: number[], ccw: boolean = true) => {
  const [u, v] = vector;
  var res;
  if (ccw) {
    res = [-v, u];
  } else {
    res = [v, -u];
  }

  return res;
};

export const offsetSegment = (segment: number[], dist: number) => {
  const [x1, y1, x2, y2] = segment;

  const [u, v] = rotateVector(unitVector(segment));

  return [x1 + dist * u, y1 + dist * v, x2 + dist * u, y2 + dist * v];
};

export const joinSegment = (seg1: number[], seg2: number[]) => {
  const [x1, y1, x2, y2] = seg1;
  const [x3, y3, x4, y4] = seg2;

  const d = (x1 - x2) * (y3 - y4) - (y1 - y2) * (x3 - x4);

  if (d === 0) {
    return null;
  } else {
    const a = x1 * y2 - y1 * x2;
    const b = x3 * y4 - y3 * x4;
    const x = (a * (x3 - x4) - (x1 - x2) * b) / d;
    const y = (a * (y3 - y4) - (y1 - y2) * b) / d;

    return [x, y];
  }
};

export const offsetLine = (line: number[], dist: number) => {
  var segments = [];
  for (var i = 0; i < line.length - 3; i += 2) {
    segments.push(line.slice(i, i + 4));
  }

  var offsetSegs = segments.map((seg) => offsetSegment(seg, dist));

  var offsetPoints = [];

  offsetPoints = offsetPoints.concat(offsetSegs[0].slice(0, 2)); // first point

  for (var i = 0; i < offsetSegs.length - 1; i += 1) {
    const seg1 = offsetSegs[i];
    const seg2 = offsetSegs[i + 1];

    const nextPoint = joinSegment(seg1, seg2);
    if (nextPoint) {
      offsetPoints = offsetPoints.concat(nextPoint);
    }
  }

  offsetPoints = offsetPoints.concat(
    offsetSegs[offsetSegs.length - 1].slice(-2)
  ); // last point

  return offsetPoints;
};

export const pointDistance = (p: number[], q: number[]) => {
  let [a, b] = p;
  let [c, d] = q;

  return Math.sqrt((a - c) ** 2 + (b - d) ** 2);
};

export const closestPoint = (points: number[][], target: number[]) => {
  return points.reduce((closest, point) => {
    const distance = pointDistance(point, target);
    if (distance < pointDistance(closest, target)) {
      return point;
    }
    return closest;
  });
};

export const getAngle = (
  line1: number[],
  line2: number[],
  inDegree: boolean = true
) => {
  const [x1, y1, x2, y2] = line1;
  const [x3, y3, x4, y4] = line2;

  // Calculate direction vectors for both lines
  const dir1 = { x: x2 - x1, y: y2 - y1 };
  const dir2 = { x: x4 - x3, y: y4 - y3 };

  // Calculate the angle between the two direction vectors
  const angle1 = Math.atan2(dir1.y, dir1.x);
  const angle2 = Math.atan2(dir2.y, dir2.x);

  // Calculate the difference between the angles
  let angleDifference = angle2 - angle1;

  // Normalize the angle to be between 0 and 2 * Math.PI
  if (angleDifference < 0) {
    angleDifference += 2 * Math.PI;
  }

  // Convert to degrees if inDegree is true
  let result = inDegree ? angleDifference * (180 / Math.PI) : angleDifference;

  // Normalize to be between 0 and 360 degrees
  return inDegree ? ((result % 360) + 360) % 360 : result;
};

export const getBoundingBoxOfPolygon: (
  polygon: number[],
  local_point: number[],
  local_unit_direction: number[]
) => {
  minX: number;
  minY: number;
  maxX: number;
  maxY: number;
  width: number;
  height: number;
} = (polygon, local_point = [0, 0], local_unit_direction = [1, 0]) => {
  const [ux, uy] = local_unit_direction.map(
    (e) =>
      e / Math.sqrt(local_unit_direction[0] ** 2 + local_unit_direction[1] ** 2)
  ); // localUnitDirection (단위 벡터)

  // 단위 방향에 직교하는 벡터 (localUnitDirection을 x축으로 보았을 때의 y축)
  const vx = -uy;
  const vy = ux;

  // 변환된 좌표에서 최소/최대 값을 찾기
  let minX = Infinity,
    maxX = -Infinity,
    minY = Infinity,
    maxY = -Infinity;

  // polygon 배열을 2개씩 묶어서 처리
  for (let i = 0; i < polygon.length; i += 2) {
    const x = polygon[i];
    const y = polygon[i + 1];

    // 원점을 localPoint로 옮기고 로컬 좌표계로 회전
    const dx = x - local_point[0];
    const dy = y - local_point[1];

    // 새로운 x, y 좌표를 로컬 좌표계로 변환
    const localX = dx * ux + dy * uy; // localUnitDirection에 따른 변환
    const localY = dx * vx + dy * vy; // 직교 벡터에 따른 변환

    // 최소/최대 값 업데이트
    if (localX < minX) minX = localX;
    if (localX > maxX) maxX = localX;
    if (localY < minY) minY = localY;
    if (localY > maxY) maxY = localY;
  }

  // Bounding box 반환: [minX, minY, width, height]
  return {
    minX,
    minY,
    maxX,
    maxY,
    width: maxX - minX,
    height: maxY - minY,
  };
};

// Arc 데이터를 SVG Path로 변환
export const convertArcToSVG = ({
  u,
  v,
  radius,
  angle,
  angleStart = 0,
  x = 0,
  y = 0,
  rotation = 0,
  scaleX = 1,
  scaleY = 1,
}) => {
  // 로컬 좌표 u, v를 rotation 값에 맞게 회전
  const rotationRad = (Math.PI / 180) * rotation;

  // 회전 변환 적용
  const rotatedU = u * Math.cos(rotationRad) - v * Math.sin(rotationRad);
  const rotatedV = u * Math.sin(rotationRad) + v * Math.cos(rotationRad);

  // 각도를 라디안으로 변환 (회전 각도 적용)
  const startAngleRad = (Math.PI / 180) * (angleStart - rotation);
  const endAngleRad = (Math.PI / 180) * (angleStart + angle - rotation);

  // 시작점과 끝점 좌표에 scaleX, scaleY를 적용
  const startX = x + (rotatedU + radius * Math.cos(startAngleRad)) * scaleX;
  const startY = y + (rotatedV - radius * Math.sin(startAngleRad)) * scaleY;
  const endX = x + (rotatedU + radius * Math.cos(endAngleRad)) * scaleX;
  const endY = y + (rotatedV - radius * Math.sin(endAngleRad)) * scaleY;

  // largeArcFlag 계산 (180도 초과 여부)
  const largeArcFlag = angle > 180 ? 1 : 0;

  return `M ${startX} ${startY} A ${radius * scaleX} ${
    radius * scaleY
  } 0 ${largeArcFlag} 0 ${endX} ${endY}`;
};

// Line 데이터를 SVG Path로 변환
export const convertLineToSVG = ({
  points,
  x = 0,
  y = 0,
  rotation = 0,
  scaleX = 1,
  scaleY = 1,
}) => {
  const radRotation = (Math.PI / 180) * rotation;
  // 시작점과 끝점에 scaleX, scaleY, rotation 적용
  const [startX, startY, ...rest] = points;
  const transformedStartX =
    x +
    (startX * Math.cos(radRotation) - startY * Math.sin(radRotation)) * scaleX;
  const transformedStartY =
    y +
    (startX * Math.sin(radRotation) + startY * Math.cos(radRotation)) * scaleY;

  const lineSegments = [];
  for (let i = 0; i < rest.length; i += 2) {
    const px = rest[i];
    const py = rest[i + 1];
    const transformedX =
      x + (px * Math.cos(radRotation) - py * Math.sin(radRotation)) * scaleX;
    const transformedY =
      y + (px * Math.sin(radRotation) + py * Math.cos(radRotation)) * scaleY;
    lineSegments.push(`L ${transformedX} ${transformedY}`);
  }

  return `M ${transformedStartX} ${transformedStartY} ${lineSegments.join(
    " "
  )}`;
};

/**
 * 여러 점(x1,y1, x2,y2, ..., xN,yN)으로 이루어진 폴리라인을
 * 두께(thickness)를 고려해 bounding box를 확장한 뒤,
 * 사각형 격자(width, height) 단위로 평행이동 복제본을 생성.
 *
 * 결과: 각 (i,j) 타일에 대응하는 "shifted polyline"을
 *       하나씩 반환 (즉, 여러 개가 생김).
 *
 * @param {object} item
 *   - { points: number[], thickness?: number }
 *     예: points = [x1, y1, x2, y2, x3, y3, ...]
 * @param {number} width   - 타일 폭
 * @param {number} height  - 타일 높이
 * @returns {Array<Array<number>>}
 *   - 각 원소는 "shiftedPoints": [sx1, sy1, sx2, sy2, ...].
 *     즉, (i, j) 타일에 대한 평행이동 후의 좌표 집합
 */
export function tileLineByWrap(item, width, height, thickness = 1) {
  const { points } = item;
  if (!points || points.length < 4) {
    // 점이 2개 미만이면 선이 안 되므로 빈 배열 리턴
    return [];
  }

  // 1) 폴리라인 전체의 min/max x,y 계산
  let minX = Infinity,
    maxX = -Infinity;
  let minY = Infinity,
    maxY = -Infinity;

  for (let i = 0; i < points.length; i += 2) {
    const x = points[i];
    const y = points[i + 1];
    if (x < minX) minX = x;
    if (x > maxX) maxX = x;
    if (y < minY) minY = y;
    if (y > maxY) maxY = y;
  }

  // 2) 두께(thickness) 반영해서 bounding box 확장
  const halfT = thickness / 2;
  minX -= halfT;
  maxX += halfT;
  minY -= halfT;
  maxY += halfT;

  // 3) 걸칠 수 있는 타일 범위
  //    floor() 결과가 음수일 수도 있으니 그대로 사용
  const iStart = Math.floor(minX / width);
  const iEnd = Math.floor(maxX / width);
  const jStart = Math.floor(minY / height);
  const jEnd = Math.floor(maxY / height);

  const shiftedPolylines = [];

  // 4) 각 (i, j) 타일마다 평행이동한 복제본 생성
  for (let i = iStart; i <= iEnd; i++) {
    for (let j = jStart; j <= jEnd; j++) {
      // 평행 이동 벡터: (-i*width, -j*height)
      const shiftedPoints = [];
      for (let idx = 0; idx < points.length; idx += 2) {
        const x = points[idx];
        const y = points[idx + 1];
        // 이동
        const sx = x - i * width;
        const sy = y - j * height;
        shiftedPoints.push(sx, sy);
      }
      shiftedPolylines.push(shiftedPoints);
    }
  }

  return shiftedPolylines;
}

/**
 * 원호(Arc): 중심 (u, v), 반지름 radius,
 * 두께 thickness, 시작각 angleStart, 총 회전각 angle.
 * bounding box (u-r, v-r) ~ (u+r, v+r)에
 * thickness를 고려해 확장 -> 각 타일에 대해 복제
 */
export function tileArcByWrap(item, width, height, thickness = 1) {
  const { u, v, radius, angle, angleStart } = item;

  // 원호의 bounding box = (u - r, v - r) ~ (u + r, v + r)
  // 두께가 있다면, stroke가 바깥쪽/안쪽으로 thickness/2씩 퍼진다고 보면
  const halfT = thickness / 2;
  const minX = u - radius - halfT;
  const maxX = u + radius + halfT;
  const minY = v - radius - halfT;
  const maxY = v + radius + halfT;

  const iStart = Math.floor(minX / width);
  const iEnd = Math.floor(maxX / width);
  const jStart = Math.floor(minY / height);
  const jEnd = Math.floor(maxY / height);

  const arcs = [];

  for (let i = iStart; i <= iEnd; i++) {
    for (let j = jStart; j <= jEnd; j++) {
      arcs.push({
        type: "Arc",
        // 평행 이동된 중심
        u: u - i * width,
        v: v - j * height,
        radius,
        angle,
        angleStart,
        thickness,
      });
    }
  }

  return arcs;
}

// Line과 Arc를 SVG Path로 결합하는 함수
export const convertToSVGPath = (data, width, height, thickness = 1) => {
  return data
    .map((item) => {
      if (item.type === "Line") {
        return tileLineByWrap(item, width, height, thickness)
          .map((points) => convertLineToSVG({ points }))
          .join(" ");
      } else if (item.type === "Arc") {
        return tileArcByWrap(item, width, height, thickness)
          .map((arcs) => convertArcToSVG(arcs))
          .join(" ");
      }
      return "";
    })
    .join(" ");
};

export const getXYCrossing = (point: number[], direction: number[]) => {
  const [px, py] = point; // 시작점 좌표
  const [dx, dy] = direction; // 방향 벡터

  let xCrossing = null;
  let yCrossing = null;

  // x축 (y = 0)과의 교차점 계산: t = -py/dy
  if (dy !== 0) {
    const tX = -py / dy;
    const x = px + tX * dx;
    xCrossing = [x, 0]; // x축과 교차하는 좌표
  }

  // y축 (x = 0)과의 교차점 계산: t = -px/dx
  if (dx !== 0) {
    const tY = -px / dx;
    const y = py + tY * dy;
    yCrossing = [0, y]; // y축과 교차하는 좌표
  }

  // point에서 가까운 교차점 반환
  if (xCrossing && yCrossing) {
    return xCrossing;
  }

  // x축 또는 y축과의 교차점만 있을 경우 반환
  return xCrossing || yCrossing;
};
