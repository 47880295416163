import useDrawContext, { DesignatorLayer } from "../hooks/useDrawContext";
import { abstractify } from "../utils/ObjectUtils";
import IconSymbol from "./IconSymbol";
import PropertyBlock from "./PropertyBlock";
import PropertyGroup from "./PropertyGroup";
import PropertyRow from "./PropertyRow";

import styles from "./PropertySectionForDesignObject.module.scss";
import classNames from "classnames/bind";

import { ReactComponent as TempAlign } from "../svgs/TempAlign.svg";
import { ReactComponent as TempModify } from "../svgs/TempModify.svg";

const cx = classNames.bind(styles);

const PropertySectionForDesignObject = ({}: {}) => {
  const { getNamedObject, updateNamedObject } = useDrawContext();
  return (
    <>
      <PropertyGroup title="Selection (1)" close>
        <PropertyRow>
          <PropertyBlock type="text-input-fill" name="Name" value="Wall1" />
        </PropertyRow>
        <PropertyRow>
          <PropertyBlock type="text-selection-fill" name="Layer" value="Wall" />
        </PropertyRow>
        <PropertyRow>
          <PropertyBlock type="only-selection" value="2D Line" />
          <PropertyBlock type="action" icon_type="show" illust="Shown" />
          <PropertyBlock type="action" icon_type="unlocked" illust="Unlocked" />
        </PropertyRow>
        <TempAlign />
      </PropertyGroup>
      <PropertyGroup title="Configuration (1)" close>
        <PropertyRow>
          <PropertyBlock type="object-selection" value="Top Left">
            <IconSymbol type="aligntopleft" />
          </PropertyBlock>
        </PropertyRow>
        <PropertyRow>
          <PropertyBlock type="text-input" name="X" value="-829" />
          <PropertyBlock type="text-input" value="549" name="Y" />
          <PropertyBlock
            type="action"
            icon_type="absoluteposition"
            illust="Absolute position"
            disabled
          />
        </PropertyRow>
        <PropertyRow>
          <PropertyBlock type="text-input" value="50" name="W" />
          <PropertyBlock type="text-input" value="10" name="H" />
          <PropertyBlock
            type="action"
            icon_type="breaken"
            illust="Not proportional"
          />
        </PropertyRow>
        <PropertyRow>
          <PropertyBlock type="object-input" value="30˚">
            <IconSymbol type="angle" />
          </PropertyBlock>
          <TempModify style={{ minWidth: "8rem" }} />
        </PropertyRow>
      </PropertyGroup>
      <PropertyGroup title="Geometry" close>
        <PropertyRow>
          <PropertyBlock
            type="text-input-fill"
            name="Start Point"
            value="-829, -539"
          />
        </PropertyRow>
        <PropertyRow>
          <PropertyBlock
            type="text-input-fill"
            name="End Point"
            value="-829, -539"
          />
        </PropertyRow>
        <PropertyRow>
          <PropertyBlock type="text-input" value="52.296" name="L" />
          <PropertyBlock type="object-input" value="45.23˚">
            <IconSymbol type="angle" />
          </PropertyBlock>
          <PropertyBlock
            type="action"
            icon_type="2dccw"
            illust="Flip orientation"
          />
        </PropertyRow>
      </PropertyGroup>
    </>
  );
};

export default PropertySectionForDesignObject;
