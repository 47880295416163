import { useEffect, useMemo, useState } from "react";
import _ from "lodash";

import styles from "./PropertyRowObject.module.scss";
import classNames from "classnames/bind";

import PropertyRow from "./PropertyRow";
import PropertyBlock from "./PropertyBlock";

import useDrawContext, {
  Abstract,
  CrossSection,
  EasyJoin,
  HatchType,
  LineObject,
  Material,
  ThicknessMaterialObject,
} from "../hooks/useDrawContext";
import { abstractify, isOverwritten } from "../utils/ObjectUtils";
import Dropdown from "./Dropdown";
import IconSymbol from "./IconSymbol";
import IconAction from "./IconAction";
import { ReactComponent as TempBaseline } from "../svgs/TempBaseline.svg";
import List from "./List";
import finish from "../imgs/material_finish.png";
import temp_cross_section from "../imgs/temp_cross_section.png";
import DraggableList from "./DraggableList";
import { CanvasContextProvider } from "./CanvasContext";
import {
  CanvasDesignObject,
  EditingObject,
} from "../design_objects/DesignObject";
import { getOffset } from "../utils/DetailUtils";
import {
  internalDetailStageOffset,
  internalDetailStageHeight,
  internalDetailStageWidth,
  internalDetailStageGap,
} from "../utils/CanvasConstants";
import { Canvas } from "@react-three/fiber";
import * as THREE from "three";
import { OrthographicCamera } from "@react-three/drei";
import { AbstractArray } from "../utils/AbstractArray";

const cx = classNames.bind(styles);

const PropertyRowObject = <T extends CrossSection | EasyJoin | HatchType>({
  object,
  children,
  illusts,
  onDelete = () => {},
  onBreak = () => {},
  onEdit = () => {},
  onClick = () => {},
  ...props
}: {
  object: Abstract<T>;
  children?: any;
  illusts?: string;
  onDelete?: (e?: any) => void;
  onBreak?: (e?: any) => void;
  onEdit?: (e?: any) => void;
  onClick?: (e?: any) => void;
}) => {
  const {
    getOverriddenNamedObject,
    updateNamedObject,
    getOverriddenDesignObject,
    getDesignObjectsByType,
  } = useDrawContext();
  const [hover, setHover] = useState(false);
  const [popup, setPopup] = useState<string | boolean>(false);
  const object_data = useMemo(
    () => getOverriddenNamedObject(object),
    [getOverriddenNamedObject, object]
  );
  const [overwritten_data, setOverwrittenData] = useState(
    object as Abstract<CrossSection>
  );

  useEffect(() => {
    if (
      (object as Abstract<CrossSection>).internalDetailType === "CrossSection"
    ) {
      onEdit(overwritten_data);
    }
  }, [overwritten_data]);

  useEffect(() => {
    if (
      (object as Abstract<CrossSection>).internalDetailType === "CrossSection"
    ) {
      setOverwrittenData(object as Abstract<CrossSection>);
    }
  }, [object]);

  const obj_thickness = useMemo(() => {
    if (
      (object as Abstract<CrossSection>).internalDetailType === "CrossSection"
    ) {
      console.log(overwritten_data, getOverriddenNamedObject(overwritten_data));
      return getOverriddenNamedObject(overwritten_data)
        .materials.getElements()
        ?.reduce(
          (prev, curr) => prev + getOverriddenDesignObject(curr).thickness,
          0
        );
    }
  }, [overwritten_data]);

  const drawing_data = useMemo(() => {
    // internal detail
    let stage_size = [
      internalDetailStageWidth,
      Math.min(obj_thickness, internalDetailStageHeight),
    ];
    let stage_offset = internalDetailStageOffset;

    if (object_data.type === "InternalDetail") {
      // let object_data = _.mergeWith(
      //   object_data,
      //   overwritten_data,
      //   (v1, v2) => v2 ?? v1
      // );
      let thickness_materials = object_data.materials
        .getElements()
        .map(getOverriddenDesignObject);
      let materials = thickness_materials.map((e) =>
        getOverriddenNamedObject(e.material)
      );
      let bounds = ((x) => [x[0][0], x[x.length - 1][1]])(
        getOffset(object_data as CrossSection, thickness_materials)
      );
      const scale =
        (stage_size[1] - 2 * internalDetailStageGap) / (bounds[1] - bounds[0]);
      const baseline = [
        stage_offset / scale,
        -bounds[0] + internalDetailStageGap / scale,
        stage_size[0] / scale - stage_offset / scale,
        -bounds[0] + internalDetailStageGap / scale,
      ];
      const internal_design_object = {
        uuid: "sample",
        type: "DesignObject",
        layer: { type: "DesignatorLayer", uuid: "admin" },
        designObjectType: "LineObject",
        geometry: { points: baseline },
        additionalParams: {},
        details: [
          {
            internalDetails: [
              { ...abstractify(object_data), ...overwritten_data },
            ],
            boundaryDetails: [],
          },
        ],
        editable: false,
      } as LineObject;
      // console.log({ scale, baseline, internal_design_object, stage_size });
      return { scale, baseline, internal_design_object, stage_size };
    }
    return null;
  }, [object, object_data, obj_thickness]);

  return (
    <PropertyRow
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      {...props}
    >
      <PropertyBlock
        type="object-selection"
        value={object_data.name}
        overwritten={isOverwritten(object)}
        illusts={illusts}
        onClick={(e) => {
          onClick(e);
          setHover(false);
        }}
        object_type={object.type}
      >
        {children}
      </PropertyBlock>

      {hover && (
        <>
          <PropertyBlock
            type="action"
            icon_type="break"
            illust="Detach asset"
            onClick={onBreak}
          />
          <PropertyBlock
            type="action"
            icon_type="edit"
            illust="Edit asset"
            onClick={() => {
              setPopup("detail");
            }}
          />
        </>
      )}
      {isOverwritten(object) && !hover ? (
        <PropertyBlock type="overwritten" />
      ) : (
        <PropertyBlock
          type="action"
          icon_type="delete"
          illust="Delete"
          onClick={onDelete}
        />
      )}
      {popup === "detail" ? (
        object_data.type === "InternalDetail" ? (
          <Dropdown
            type="left-top"
            padding={0}
            onClose={() => setPopup(false)}
            style={{
              top: "-.5rem",
              padding: ".5rem 0",
              borderRadius: ".25rem",
              maxHeight: "calc(100vh - 2rem)",
            }}
          >
            <PropertyRow>
              <PropertyBlock type="group-title">
                Edit Cross Section
              </PropertyBlock>
              <PropertyBlock type="action" icon_type="add" illust="Add" />
              <PropertyBlock type="action" icon_type="close" illust="Close" />
            </PropertyRow>
            {isOverwritten(object) && (
              <PropertyRow>
                <PropertyBlock type="overwritten" />
                <div style={{ height: "2rem", width: "100%" }}></div>
                <PropertyBlock
                  type="action"
                  icon_type="sync"
                  illust="Sync to Original Asset"
                  onClick={() => {
                    updateNamedObject(
                      abstractify(object_data as CrossSection),
                      overwritten_data
                    );
                    setOverwrittenData((data) => abstractify(data));
                  }}
                />
                <PropertyBlock
                  type="action"
                  icon_type="reset"
                  illust="Reset Changes"
                  onClick={() => {
                    setOverwrittenData((data) => abstractify(data));
                  }}
                />
              </PropertyRow>
            )}
            <PropertyRow>
              <PropertyBlock
                type="long-text"
                name="Name"
                value={object_data.name}
              />
            </PropertyRow>
            <PropertyRow>
              <PropertyBlock type="object-input" value={obj_thickness}>
                <IconSymbol type="thickness" />
              </PropertyBlock>
              <PropertyBlock
                type="action"
                icon_type="towindow"
                illust="Edit in Window"
              />
            </PropertyRow>
            <List align="center">
              <PropertyBlock
                type="action"
                icon_type="add"
                illust="Add Material"
              />
              {/* <img src={temp_cross_section} /> */}
              {drawing_data && (
                <CanvasContextProvider>
                  <Canvas
                    gl={{
                      toneMapping: THREE.NoToneMapping, // Ensures no tone mapping adjustments
                      antialias: true,
                    }}
                  >
                    <OrthographicCamera
                      makeDefault
                      position={[0, 0, 10]}
                      zoom={1}
                      left={0}
                      right={drawing_data.stage_size[0]}
                      top={0}
                      bottom={drawing_data.stage_size[1]}
                    />

                    {/* <OrbitControls
                          enableRotate={false}
                          enableZoom={false}
                          enableDamping={false}
                          enablePan={true}
                        /> */}
                    <CanvasDesignObject
                      design_object={drawing_data.internal_design_object}
                    />
                    <EditingObject
                      current_points={drawing_data.baseline}
                      is_scope={true}
                      editable={false}
                      design_object={drawing_data.internal_design_object}
                      scale={drawing_data.scale}
                    />
                  </Canvas>
                  {/* <Stage
                    width={drawing_data.stage_size[0]}
                    height={drawing_data.stage_size[1]}
                    scaleX={drawing_data.scale}
                    scaleY={drawing_data.scale}
                  >
                    <Layer>
                      <CanvasDesignObject
                        design_object={drawing_data.internal_design_object}
                      />
                      <EditingObject
                        current_points={drawing_data.baseline}
                        is_scope={true}
                        editable={false}
                        design_object={drawing_data.internal_design_object}
                        scale={drawing_data.scale}
                      />
                    </Layer>
                  </Stage> */}
                </CanvasContextProvider>
              )}
              <PropertyBlock
                type="action"
                icon_type="add"
                illust="Add Material"
              />
            </List>
            <PropertyRow>
              <PropertyBlock
                type="subgroup"
                overwritten={overwritten_data.baseCoordinates}
              >
                Baseline Position
              </PropertyBlock>
            </PropertyRow>
            <PropertyRow>
              <TempBaseline style={{ minWidth: "4rem" }} />
              <List type="column" fill>
                <List type="row" fill>
                  <PropertyBlock
                    type="object-selection"
                    value={
                      getOverriddenDesignObject(
                        object_data.materials.getElementByIndex(
                          object_data.baseCoordinates.materialIndex
                        )
                      ).name
                    }
                    custom_dropdown_list={object_data.materials}
                    onClick={(_, idx) => {
                      setOverwrittenData((data) => ({
                        ...data,
                        baseCoordinates: {
                          ...((overwritten_data as Partial<CrossSection>)
                            .baseCoordinates
                            ? overwritten_data
                            : (object_data as CrossSection)
                          ).baseCoordinates,
                          materialIndex: idx,
                        },
                      }));
                    }}
                    // overwritten={
                    //   overwritten_data.baseCoordinates?.materialIndex
                    // }
                  >
                    <IconSymbol type="material" />
                  </PropertyBlock>
                  <PropertyBlock
                    type="action"
                    icon_type="match"
                    illust="Match Material"
                  />
                </List>
                <List type="row" fill>
                  <PropertyBlock
                    type="only-selection"
                    value={(object_data as CrossSection).baseCoordinates.type}
                    // overwritten={overwritten_data.baseCoordinates?.type}
                  >
                    <IconSymbol type="material" />
                  </PropertyBlock>
                  <PropertyBlock
                    type="object-input"
                    value={(object_data as CrossSection).baseCoordinates.value}
                    onChange={(event) => {
                      setOverwrittenData((data) => ({
                        ...data,
                        baseCoordinates: {
                          ...((overwritten_data as Partial<CrossSection>)
                            .baseCoordinates
                            ? overwritten_data
                            : (object_data as CrossSection)
                          ).baseCoordinates,
                          value: Number(event.target.value) || 0,
                        },
                      }));
                    }}
                    // overwritten={overwritten_data.baseCoordinates?.value}
                  >
                    <IconSymbol type="baseoffset" />
                  </PropertyBlock>
                </List>
              </List>
            </PropertyRow>

            <PropertyRow>
              <PropertyBlock
                type="subgroup"
                overwritten={overwritten_data.materials}
              >
                Materials
              </PropertyBlock>
              {overwritten_data.materials && (
                <PropertyBlock
                  type="action"
                  icon_type="reset"
                  illust="Reset Changes"
                  onClick={() => {
                    setOverwrittenData((data) => ({
                      ...data,
                      materials: undefined,
                    }));
                  }}
                />
              )}
              <PropertyBlock
                type="action"
                icon_type="add"
                illust="Add Material"
                onClick={(obj) => {
                  if (!(overwritten_data as Partial<CrossSection>).materials) {
                    setOverwrittenData((data) => ({
                      ...data,
                      materials: new AbstractArray([
                        ...object_data.materials.getElements(),
                        abstractify(
                          Object.values(
                            getDesignObjectsByType<ThicknessMaterialObject>(
                              "ThicknessMaterial"
                            )
                          )[0]
                        ),
                      ]),
                    }));
                  } else {
                    setOverwrittenData((data) => ({
                      ...data,
                      materials: new AbstractArray([
                        ...(
                          overwritten_data as Partial<CrossSection>
                        ).materials.getElements(),
                        abstractify(
                          Object.values(
                            getDesignObjectsByType<ThicknessMaterialObject>(
                              "ThicknessMaterial"
                            )
                          )[0]
                        ),
                      ]),
                    }));
                  }
                }}
              />
            </PropertyRow>
            <DraggableList
              onChange={(reordered_idxs) => {
                const base_idx = object_data.baseCoordinates.materialIndex;
                const base_idx_re = reordered_idxs.findIndex(
                  (r_i) => r_i == base_idx
                );
                console.log(base_idx, base_idx_re);
                setOverwrittenData((data) => ({
                  ...data,
                  ...(base_idx === base_idx_re
                    ? {}
                    : {
                        baseCoordinates: {
                          ...((object_data as Partial<CrossSection>)
                            .baseCoordinates
                            ? overwritten_data
                            : (object_data as CrossSection)
                          ).baseCoordinates,
                          materialIndex: base_idx_re,
                        },
                      }),
                  materials: object_data.materials.reOrder(reordered_idxs),
                  // ...{}:{baseCoordinates: {
                  //   ...((overwritten_data as Partial<CrossSection>)
                  //     .baseCoordinates
                  //     ? overwritten_data
                  //     : (object_data as CrossSection)
                  //   ).baseCoordinates,
                  //   value: Number(event.target.value),
                  // }},
                }));
              }}
            >
              {(overwritten_data.materials ?? object_data.materials)
                .getElements()
                .map((mat, idx) => (
                  <PropertyRow key={idx}>
                    <PropertyBlock
                      type="object-selection"
                      value={getOverriddenDesignObject(mat).name}
                      object_type={mat.type}
                      onClick={(obj) => {
                        setOverwrittenData((data) => ({
                          ...data,
                          materials: (
                            overwritten_data.materials ?? object_data.materials
                          ).updateElementByIndex(idx, obj),
                        }));
                      }}
                    >
                      <img src={finish} />
                    </PropertyBlock>
                    {getOverriddenDesignObject(mat).variable && (
                      <PropertyBlock
                        type="object-input"
                        value={getOverriddenDesignObject(mat).thickness}
                        style={{ width: "8rem" }}
                      >
                        <IconSymbol type="thickness" />
                      </PropertyBlock>
                    )}
                    <PropertyBlock
                      type="action"
                      icon_type="delete"
                      illust="Delete"
                      onClick={() => {
                        setOverwrittenData((data) => ({
                          ...data,
                          materials: (
                            overwritten_data.materials ?? object_data.materials
                          ).deleteElementByIndex(idx),
                        }));
                      }}
                    />
                  </PropertyRow>
                ))}
            </DraggableList>
          </Dropdown>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
    </PropertyRow>
  );
};

export default PropertyRowObject;
