import { useEffect, useRef, useState } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, OrthographicCamera } from "@react-three/drei";
import { OrthographicCamera as OrthographicCameraType } from "three";
import useDrawContext, { LineObject } from "../hooks/useDrawContext";
import { v4 as uuid } from "uuid";
import {
  EditingObject,
  CanvasDesignObject,
} from "../design_objects/DesignObject";
import {
  abstractify,
  isDesignObject,
  isLineObject,
} from "../utils/ObjectUtils";
import useCanvasContext from "./CanvasContext";
import { getSnappedPoint } from "../utils/CanvasUtils";
import BoundaryDetailGrid from "../design_objects/BoundaryDetailGrid";
import * as THREE from "three";
import useMeasure from "react-use-measure";

export const SelectedDesignObject = (draw_context) => {
  const selected = draw_context.current_selection.filter(isDesignObject);
  return selected.length > 0 ? selected[0] : null;
};

const CanvasWindow = ({ width, height, use_stats = true }) => {
  const { draw_context, setDrawContext, getDesignObject, getNamedObject } =
    useDrawContext();
  const { canvasContext, setCanvasContext } = useCanvasContext();
  const stageRef = useRef(null);
  const [currentDrawing, setCurrentDrawing] = useState<number[]>([]);
  const cameraRef = useRef<OrthographicCameraType>(null);
  const [ref, bounds] = useMeasure();
  const [isDragging, setIsDragging] = useState(false);
  const statsRef = useRef(null);

  // useEffect(() => {
  //   if (use_stats) {
  //     const stats = new Stats();
  //     stats.showPanel(0); // 0: FPS, 1: 메모리 사용량, 2: CPU (JavaScript 성능)

  //     statsRef.current.appendChild(stats.dom);

  //     const animate = () => {
  //       stats.begin();
  //       // 측정할 애니메이션이나 렌더링 로직이 있으면 여기에 넣습니다
  //       stats.end();
  //       requestAnimationFrame(animate);
  //     };

  //     requestAnimationFrame(animate);

  //     return () => {
  //       // 컴포넌트가 언마운트될 때 애니메이션 중단
  //       cancelAnimationFrame(animate as any);
  //     };
  //   }
  // }, [use_stats]);

  useEffect(() => {
    if (stageRef.current) {
      setCanvasContext({ stage: stageRef.current });
    }
  }, [stageRef.current]);

  const layerRef = useRef(null);

  // useEffect(() => {
  //   if (layerRef.current) {
  //     // WebGL 컨텍스트 가져오기
  //     const gl = layerRef.current.getContext("webgl");

  //     if (!gl) {
  //       console.error("WebGL not supported");
  //       return;
  //     }

  //     // WebGL 초기화 (간단한 설정)
  //     gl.clearColor(0.0, 0.0, 0.0, 1.0); // 검은 배경
  //     gl.clear(gl.COLOR_BUFFER_BIT); // 배경 초기화

  //     // 여기서 WebGL을 사용한 추가 그래픽 작업 수행 가능
  //   }
  // }, [layerRef]);

  const updateDesignObjectData = (newBaseLine: number[]) => {
    if (newBaseLine.length > 2) {
      const newLine: LineObject = {
        uuid: uuid(),
        type: "DesignObject",
        designObjectType: "LineObject",
        layer: { ...draw_context.layers.current },
        geometry: { points: newBaseLine.slice() },
        additionalParams: {},
        details: [],
        editable: true,
      };
      setDrawContext({
        designObjects: {
          ...draw_context.designObjects,
          [newLine.uuid]: newLine,
        },
      });
    }
  };

  // Handle mode changes
  useEffect(() => {
    if (draw_context.mode === "base") {
      setCurrentDrawing([]);
    }
    if (draw_context.mode !== "edit") {
      setDrawContext({ current_selection: [] });
    }
  }, [draw_context.mode]);

  // Update baseline points and joints
  useEffect(() => {
    const jointMap = new Map();
    const addJoint = (x: number, y: number, val: any) => {
      if (!jointMap.has(x)) {
        jointMap.set(x, new Map());
      }
      if (!jointMap.get(x).has(y)) {
        jointMap.get(x).set(y, []);
      }
      jointMap.get(x).get(y).push(val);
    };

    const getAllJoints = (jointMap) => {
      const allPoints = [];
      const allJoints: { [uuid: string]: any } = {};
      for (let [x, yMap] of jointMap) {
        for (let [y, anchors] of yMap) {
          allPoints.push([x, y]);
          if (anchors.length > 1) {
            const new_uuid = uuid();
            allJoints[new_uuid] = {
              uuid: new_uuid,
              type: "JointObject",
              anchors,
            };
          }
        }
      }
      return [allPoints, allJoints] as const;
    };

    for (const line of Object.values(draw_context.designObjects).filter(
      isLineObject
    )) {
      for (let i = 0; i < line.geometry.points.length; i += 2) {
        if (
          line.uuid !== SelectedDesignObject(draw_context)?.uuid ||
          i / 2 !== canvasContext.editPoint?.pointIndex
        ) {
          const [x, y] = line.geometry.points.slice(i, i + 2);
          addJoint(x, y, {
            designObject: abstractify(line),
            pointIndex: i / 2,
          });
        }
      }
    }

    const [points, joints] = getAllJoints(jointMap);
    setCanvasContext({ jointObjects: joints, baseLinePoints: points });
  }, [draw_context.designObjects]);

  // Click handler
  const handleStageClick = (e: any) => {
    if (isDragging) {
      return;
    }

    const pos = getSnappedPoint(
      // stageRef.current,
      e,
      cameraRef.current,
      canvasContext.baseLinePoints,
      canvasContext.scale
    );
    if (draw_context.mode === "polyline") {
      if (e.button === 0) {
        if (currentDrawing.length > 2) {
          updateDesignObjectData(
            currentDrawing.slice(0, currentDrawing.length - 2).concat(pos)
          );
          setDrawContext({ mode: "base", step: 0 });
          setCurrentDrawing([]);
        } else {
          setDrawContext({ step: draw_context.step + 1 });
          setCurrentDrawing([
            ...pos,
            pos[0] + 10 / canvasContext.scale,
            pos[1],
          ]);
        }
      } else if (e.button === 2) {
        setCurrentDrawing([]);
      }
    } else if (draw_context.mode === "edit" && canvasContext.editPoint) {
      const selectedUuid = SelectedDesignObject(draw_context)?.uuid;
      const newDesignObject: LineObject = structuredClone(
        getDesignObject(SelectedDesignObject(draw_context))
      );
      newDesignObject.geometry.points[canvasContext.editPoint.pointIndex * 2] =
        pos[0];
      newDesignObject.geometry.points[
        canvasContext.editPoint.pointIndex * 2 + 1
      ] = pos[1];

      setDrawContext({
        designObjects: {
          ...draw_context.designObjects,
          [selectedUuid]: newDesignObject,
        },
        current_selection: [],
      });
      setCanvasContext({ editPoint: null });
    } else if (draw_context.current_selection.length > 0) {
      if (e.eventObject != null && !e.drag) {
        setDrawContext({ current_selection: [] });
      }
    }
  };

  const handleMissClick = (e) => {
    if (e.type === "click" && draw_context.scope == null) {
      setDrawContext({
        mode: "base",
        step: 0,
        scope: null,
      });
    }
    if (e.type === "dblclick" && draw_context.scope != null) {
      setDrawContext({
        mode: "base",
        step: 0,
        scope: null,
      });
    }
  };

  // Mouse move handler
  const handleMouseMove = (e: any) => {
    if (e.buttons === 1) {
      setIsDragging(true); // 드래그 중으로 설정
    }
    if (draw_context.mode === "polyline") {
      const pos = getSnappedPoint(
        // stageRef.current,
        e,
        cameraRef.current,
        canvasContext.baseLinePoints,
        canvasContext.scale
      );
      if (currentDrawing.length > 2) {
        setCurrentDrawing(
          currentDrawing.slice(0, currentDrawing.length - 2).concat(pos)
        );
      } else {
        setCurrentDrawing(pos);
      }
    }

    if (draw_context.mode === "edit" && canvasContext.editPoint) {
      const line = getDesignObject(
        canvasContext.editPoint.designObject
      ) as LineObject;

      if (line) {
        const pos = getSnappedPoint(
          // stageRef.current,
          e,
          cameraRef.current,
          canvasContext.baseLinePoints,
          canvasContext.scale
        );
        let point_index = canvasContext.editPoint.pointIndex;

        let new_line = line.geometry.points
          .slice(0, 2 * point_index)
          .concat(pos)
          .concat(line.geometry.points.slice(2 * (point_index + 1)));
        setCurrentDrawing(new_line);
      }
    }
  };

  // Pan handler
  const handleMouseDown = (e: any) => {
    setIsDragging(false);
  };

  return (
    <Canvas
      ref={ref}
      orthographic
      gl={{
        toneMapping: THREE.NoToneMapping, // Ensures no tone mapping adjustments
        antialias: true,
      }}
      onClick={handleStageClick}
      onPointerMove={handleMouseMove}
      onPointerDown={handleMouseDown}
      onPointerMissed={handleMissClick}
      style={{ position: "absolute" }}
    >
      <OrthographicCamera
        ref={cameraRef}
        makeDefault
        manual
        position={[0, 0, 10]}
        zoom={1}
        left={-bounds.width / 2}
        right={bounds.width / 2}
        top={bounds.height / 2}
        bottom={-bounds.height / 2}
        onUpdate={(camera) => {
          setCanvasContext({ camera: camera });
        }}
      />

      <OrbitControls
        enableRotate={false}
        enableZoom={true}
        enableDamping={false}
        enablePan={true}
        zoomSpeed={3}
        onChange={(e) => {
          console.log(cameraRef.current);
          if (cameraRef.current) {
            setCanvasContext({
              scale: cameraRef.current.zoom,
            });
          }
        }}
        camera={cameraRef.current}
        zoomToCursor
      />

      <color
        attach="background"
        args={[draw_context.global_setting.background_color || "white"]}
      />
      {Object.values(draw_context.designObjects).map((design_object, idx) => (
        <CanvasDesignObject key={idx} design_object={design_object} />
      ))}
      {Object.values(canvasContext.jointObjects).map((joint, idx) => (
        <BoundaryDetailGrid key={idx} joint={joint} />
      ))}
      <EditingObject
        current_points={currentDrawing}
        is_scope={false}
        editable={false}
      />
    </Canvas>
  );
};

export default CanvasWindow;
