import useDrawContext, {
  BoundaryDetail,
  DesignatorLayer,
  InternalDetail,
  LineObject,
  ThicknessMaterialObject,
} from "../hooks/useDrawContext";
import { abstractify } from "../utils/ObjectUtils";
import IconSymbol from "./IconSymbol";
import PropertyBlock from "./PropertyBlock";
import PropertyGroup from "./PropertyGroup";
import PropertyRow from "./PropertyRow";

import styles from "./PropertyGroupForThicknessMaterial.module.scss";
import classNames from "classnames/bind";

import { Fragment } from "react/jsx-runtime";
import DropdownCommand from "./DropdownCommand";
import PropertyRowObject from "./PropertyRowObject";

import StandardSlabImg from "../imgs/standard_slab.png";

const cx = classNames.bind(styles);

const PropertyGroupForThicknessMaterial = ({
  data,
}: {
  data: ThicknessMaterialObject;
}) => {
  const {
    getNamedObject,
    updateNamedObject,
    updateDesignObject,
    getNamedObjectsByType,
    getOverriddenNamedObject,
  } = useDrawContext();
  return (
    <PropertyGroup
      title="Thickness Material"
      actions={[{ icon_type: "assets", illust: "Assets" }]}
    >
      <PropertyRow>
        <PropertyBlock
          type="long-text"
          name="Name"
          value={getOverriddenNamedObject(data.material)?.name}
        />
      </PropertyRow>
      <PropertyRow>
        <PropertyBlock type="object-input" value={data.thickness}>
          <IconSymbol type="thickness" />
        </PropertyBlock>
        <PropertyBlock
          type="text-input"
          name="Var."
          value={
            getOverriddenNamedObject(data.material)?.variable ? "True" : "False"
          }
          italic
        />
        <PropertyBlock type="action" icon_type="none" disabled />
      </PropertyRow>
      <PropertyRow>
        <PropertyBlock type="subgroup">Fill</PropertyBlock>
        <PropertyBlock type="action" icon_type="assets" illust="Assets" />
        <PropertyBlock type="action" icon_type="add" illust="Add" />
      </PropertyRow>
      {getOverriddenNamedObject(data.material)?.hatch && (
        <PropertyRowObject
          object={getOverriddenNamedObject(data.material)?.hatch}
          onDelete={() => {}}
          onClick={(e) => {
            updateDesignObject(abstractify(data), {
              material: { ...data.material, hatch: e },
            });
          }}
          onEdit={(e) => {}}
        >
          <IconSymbol type="material" />
        </PropertyRowObject>
      )}

      {getOverriddenNamedObject(data.material)?.hatchLayer && (
        <PropertyRow>
          <PropertyBlock
            type="text-selection-fill"
            name="Layer"
            value={
              getNamedObject(
                getOverriddenNamedObject(data.material)?.hatchLayer
              ).name
            }
          />
        </PropertyRow>
      )}
      <PropertyRow>
        <PropertyBlock type="subgroup">Stroke</PropertyBlock>
        <PropertyBlock type="action" icon_type="assets" illust="Assets" />
        <PropertyBlock type="action" icon_type="add" illust="Add" />
      </PropertyRow>
      <PropertyRow>
        <PropertyBlock
          type="object-selection"
          value={"by Layer"}
          object_type="ColorStyle"
        >
          <div
            className={cx("frame-color")}
            style={{
              backgroundColor: getNamedObject(
                getNamedObject(
                  getOverriddenNamedObject(data.material)?.outlineLayer
                ).color
              ).value,
            }}
          />
        </PropertyBlock>
        <PropertyBlock type="action" icon_type="delete" illust="Delete" />
      </PropertyRow>
      <PropertyRow>
        <PropertyBlock
          type="text-selection-fill"
          name="Layer"
          value={
            getNamedObject(
              getOverriddenNamedObject(data.material)?.outlineLayer
            ).name
          }
        />
      </PropertyRow>
    </PropertyGroup>
  );
};

export default PropertyGroupForThicknessMaterial;
