import useDrawContext, {
  AbstractDesignatorObject,
  DesignatorLayer,
  DesignObject,
  DrawContextStateProps,
  HatchType,
  Material,
  ThicknessMaterialObject,
} from "../hooks/useDrawContext";
import {
  abstractify,
  isLineObject,
  getNewAbstractDesignatorObject,
  getNewUUid,
} from "../utils/ObjectUtils";
import { layerToLinestyle, leftClickOnly } from "../utils/CanvasUtils";
import Hatch from "./Hatch";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { fadedOpacity, selectedColor } from "../utils/CanvasConstants";
import { getXYCrossing } from "../utils/GeomUtils";
import { Group, Polygon, Polyline } from "../utils/Geometry";
import { layers } from "../utils/getInitialDrawContext";

const MaterialObject = forwardRef(
  (
    {
      design_object,
      boundary,
      active_sides,
      thickness_material,
      selected,
      disabled,
      onClick,
    }: {
      design_object: DesignObject;
      boundary: number[];
      active_sides: boolean[];
      thickness_material: ThicknessMaterialObject;
      selected: boolean;
      disabled?: boolean;
      onClick?: (a: any) => any;
    },
    ref
  ) => {
    const {
      draw_context,
      getNamedObject,
      getOverriddenNamedObject,
      setDrawContext,
    } = useDrawContext();
    const [instance_uuid, setInstanceUuid] = useState<string>();
    const material = useMemo(
      () => getOverriddenNamedObject(thickness_material.material),
      [thickness_material]
    );

    useEffect(() => {
      const new_uuid = getNewUUid();
      setInstanceUuid(new_uuid);
      console.log("updating material uuid");
      setDrawContext((draw_context) => ({
        designObjects: {
          ...draw_context.designObjects,
          ...{
            [new_uuid]: {
              uuid: new_uuid,
              ref_uuid: material.uuid,
              material: abstractify(material),
              type: "DesignObject",
              designObjectType: "ThicknessMaterial",
              layer: layers.admin,
            },
          },
        },
      }));
    }, []);
    useImperativeHandle(
      ref,
      () => ({
        uuid: instance_uuid,
        ref_uuid: material.uuid,
        type: "ThicknessMaterial",
      }),
      [instance_uuid, material]
    );
    const curr_selected = useMemo(
      () =>
        selected ||
        draw_context.current_selection.filter((e) => e.uuid === instance_uuid)
          .length > 0,
      [draw_context.current_selection, instance_uuid]
    );
    // check layer activated
    let design_object_layer = getNamedObject(design_object.layer);

    // outline
    const outline_layer = useMemo(
      () => getNamedObject(material.outlineLayer),
      [material, getNamedObject]
    );
    const linestyle = useMemo(
      () =>
        layerToLinestyle(
          outline_layer,
          getNamedObject,
          draw_context.global_setting
        ),
      [outline_layer, getNamedObject, draw_context]
    );

    let closed_boundary = boundary.concat(boundary.slice(0, 2));
    let active_segments: number[][] = [];

    for (let i = 0; i < active_sides.length; i++) {
      if (!active_sides[i]) {
        continue;
      }
      active_segments.push(closed_boundary.slice(2 * i, 2 * (i + 2)));
    }

    const SegmentLine = ({ segment, ...props }) => {
      return (
        <Polyline
          layer={abstractify(outline_layer)}
          points={segment}
          {...linestyle}
          {...(curr_selected ? { stroke: selectedColor } : {})}
          strokeScaleEnabled={
            draw_context.global_setting.rendering_style === "print"
          }
          {...props}
        />
      );
    };

    // hatch
    const hatch: HatchType = getNamedObject(material.hatch);
    const hatch_layer: DesignatorLayer = getNamedObject(material.hatchLayer);

    // useEffect(() => {
    //   const d = hatch.value;
    // }, []);

    const hatch_props = useMemo(
      () => ({
        type: hatch?.value,
        boundary,
        pivot_point: hatch?.value.includes("Ins")
          ? getXYCrossing(
              [boundary[0], boundary[1]],
              [boundary[2] - boundary[0], boundary[3] - boundary[1]]
            )
          : [0, 0],
        unit_direction: hatch?.value.includes("Ins")
          ? [boundary[2] - boundary[0], boundary[3] - boundary[1]]
          : [1, 0],
        scale: hatch?.value.includes("Ins")
          ? (thickness_material.thickness ?? 1) / 100
          : hatch?.scale ?? 1,
        layer: abstractify(hatch_layer),
        // debug: hatch?.value.includes("Ins"),
      }),
      [boundary, hatch, hatch_layer]
    );

    return (
      <Group
        opacity={disabled ? fadedOpacity : 1}
        onClick={leftClickOnly(() => {
          if (draw_context.scope) {
            console.log(material, instance_uuid);
            setDrawContext({
              current_selection: [
                {
                  uuid: instance_uuid,
                  ref_uuid: material.uuid,
                  type: "DesignObject",
                },
              ],
            });
          }
          // console.log(draw_context, abstract_obj);
        })}
        renderOrder={curr_selected ? 5 : 0}
        depthTest={!curr_selected}
        depthWrite={!curr_selected}
      >
        {design_object_layer.activated &&
          outline_layer.activated &&
          active_segments.map((segment, idx) => (
            <SegmentLine
              key={idx}
              segment={segment}
              renderOrder={999}
              opacity={disabled ? fadedOpacity : 1}
            />
          ))}
        {
          <Polygon
            points={boundary}
            fill={selectedColor}
            strokeWidth={0}
            opacity={curr_selected ? 0.05 : 0}
            closed={true}
            stroke={null}
            renderOrder={10}
          />
        }
        {design_object_layer.activated && hatch_layer?.activated && (
          <Hatch
            {...hatch_props}
            selected={curr_selected}
            useCopy={hatch?.value.includes("Ins")}
            opacity={disabled ? fadedOpacity : 1}
          />
        )}
      </Group>
    );
  }
);

export default MaterialObject;
