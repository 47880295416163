import { Fragment, useMemo, useState } from "react";
import List from "./List";

import styles from "./PropertySection.module.scss";
import classNames from "classnames/bind";
import { ReactComponent as IconActionAdd } from "../svgs/IconActionAdd.svg";
import { ReactComponent as TempAlign } from "../svgs/TempAlign.svg";
import { ReactComponent as TempModify } from "../svgs/TempModify.svg";
import Division from "./Division";
import PropertyRow from "./PropertyRow";
import PropertyBlock from "./PropertyBlock";
import PropertyGroup from "./PropertyGroup";
import AutoScrollContainer from "./AutoScrollContainer";
import Account from "./Account";
import IconSymbol from "./IconSymbol";

import DropdownCommand from "./DropdownCommand";
import useDrawContext, {
  BoundaryDetail,
  DesignatorLayer,
  InternalDetail,
  LineObject,
  Material,
  ThicknessMaterialObject,
} from "../hooks/useDrawContext";
import {
  abstractify,
  getCorrectColor,
  getScaleDenominator,
  isDesignObject,
  isOverwritten,
} from "../utils/ObjectUtils";
import PropertyRowObject from "./PropertyRowObject";
import DraggableList from "./DraggableList";
import PropertySectionForLayer from "./PropertySectionForLayer";
import PropertySectionForDesignObject from "./PropertySectionForDesignObject";
import PropertyGroupForDetail from "./PropertyGroupForDetail";
import PropertyGroupForThicknessMaterial from "./PropertyGroupForThicknessMaterial";
import PropertyGroupForBackground from "./PropertyGroupForBackground";

const cx = classNames.bind(styles);

const PropertySection = ({}: any) => {
  const {
    draw_context,
    setDrawContext,
    getNamedObject,
    getDesignObject,
    getNamedObjectsByType,
    updateDesignObject,
    updateNamedObject,
    getOverriddenDesignObject,
    getOverriddenNamedObject,
  } = useDrawContext();
  const { curr, curr_data } = useMemo(() => {
    const curr = draw_context.current_selection[0];
    const curr_data = (
      isDesignObject(curr)
        ? getOverriddenDesignObject(curr)
        : getOverriddenNamedObject(curr)
    ) as LineObject | DesignatorLayer | ThicknessMaterialObject | undefined;

    return {
      curr,
      curr_data,
    };
  }, [draw_context]);

  return (
    <div className={cx("wrapper")}>
      <List type="column" attach="start" fill>
        <List
          type="row"
          attach="space"
          padding={0.5}
          fill
          style={{
            minHeight: "2rem",
          }}
        >
          <Account
            symbol="C"
            name="Changyong Kim"
            background_color="#075662"
            color="#f0da55"
          />
          <IconActionAdd />
        </List>
        <Division />

        <AutoScrollContainer>
          <List type="column" attach="start" align="left" fill>
            {curr_data?.type === "DesignatorLayer" ? (
              <PropertySectionForLayer data={curr_data} />
            ) : curr_data?.type === "DesignObject" ? (
              <>
                <PropertySectionForDesignObject />
                {curr_data.designObjectType === "LineObject" ? (
                  <PropertyGroupForDetail data={curr_data} />
                ) : curr_data.designObjectType === "ThicknessMaterial" ? (
                  <PropertyGroupForThicknessMaterial data={curr_data} />
                ) : null}
                <PropertyGroup
                  title="Additional Parameters"
                  actions={[
                    { icon_type: "filter", illust: "Filter" },
                    { icon_type: "add", illust: "Add" },
                  ]}
                  close
                >
                  <PropertyRow>
                    <PropertyBlock
                      type="long-selection"
                      name="IFC type"
                      value="Wall"
                    ></PropertyBlock>
                  </PropertyRow>
                  <PropertyRow>
                    <PropertyBlock
                      type="long-text"
                      name="Owner"
                      value="Changyong"
                    ></PropertyBlock>
                  </PropertyRow>
                </PropertyGroup>
              </>
            ) : (
              <PropertyGroupForBackground />
            )}

            <div style={{ minHeight: "8rem", minWidth: "15rem" }}></div>
          </List>
        </AutoScrollContainer>
        <Division />
        <List type="column" attach="start" align="left">
          <PropertyGroup
            title="Project Info"
            actions={[{ icon_type: "more", illust: "More info" }]}
            close
          >
            <PropertyRow>
              <PropertyBlock type="long-text" name="Title" value="Project 1" />
            </PropertyRow>
          </PropertyGroup>
          <PropertyGroup
            title="Drawing Info"
            actions={[{ icon_type: "more", illust: "More info" }]}
            no_divider
            close
          >
            <PropertyRow>
              <PropertyBlock type="long-text" name="No." value="A-701" />
            </PropertyRow>
          </PropertyGroup>
        </List>
        <Division />
        <div style={{ minHeight: "64px" }}>
          <PropertyRow>
            <PropertyBlock type="group-title">Export</PropertyBlock>
            <PropertyBlock
              type="action"
              icon_type="copy"
              illust="Copy"
              // disabled
            />
            <PropertyBlock
              type="action"
              icon_type="download"
              illust="Download"
              onClick={() => {
                const fileUrl = "/241107test.dwg"; // 다운로드할 파일의 경로
                const link = document.createElement("a");
                link.href = fileUrl;
                link.download = "Changyong Kim_Project 1_A-701.dxf"; // 다운로드될 파일 이름 지정
                link.click();
              }}
            />
            <PropertyBlock
              type="action"
              icon_type="more"
              illust="More options"
            />
          </PropertyRow>
        </div>
      </List>
    </div>
  );
};

export default PropertySection;
