import React, {
  createContext,
  Dispatch,
  Reducer,
  useContext,
  useReducer,
  useState,
} from "react";
import { v4 as uuid } from "uuid";
import { types } from "util";
import { abstractify, getNewUUid } from "../utils/ObjectUtils";
import { getInitialDrawContext } from "../utils/getInitialDrawContext";
import _ from "lodash";
import { AbstractArray } from "../utils/AbstractArray";

const AllModes = ["base", "polyline", "edit"];
type ModeTuple = typeof AllModes;
export type Mode = ModeTuple[number];

export const isMode = (value: string) => {
  return AllModes.includes(value as Mode);
};

export type UUID = string;

type PartiallyOptional<T, K extends keyof T> = Pick<T, K> & Partial<Omit<T, K>>;

export type Abstract<T extends AbstractDesignatorObject> = PartiallyOptional<
  T,
  "uuid" | "type"
>;

type TEMP_OBJ = ThicknessMaterialObject | LineObject;
type TEMP_MAP<T extends DesignObject, U> = T extends U ? Abstract<T> : never;
type TEMP_ABSTRACT = TEMP_MAP<TEMP_OBJ, TEMP_OBJ>;

export interface DrawContextStateProps {
  mode: Mode;
  step: number;
  scope: Abstract<DesignObject> | null;
  layers: {
    all: Abstract<DesignatorLayer>[];
    current: Abstract<DesignatorLayer>;
  };
  namedObjects: {
    [uuid: UUID]: NamedObject;
  };
  global_setting: GlobalSetting;
  current_selection: AbstractDesignatorObject[];
  designObjects: { [uuid: UUID]: DesignObject | TEMP_ABSTRACT };
  // editPointIndex: number | null;
  // jointObjects: { [uuid: UUID]: JointObject };
}

export interface GlobalSetting extends NamedObject {
  type: "GlobalSetting";
  background_color: string;
  rendering_style: "print" | "layer" | "uniform";
  scale_denominator: number;
}

export interface DrawContextActionProps {
  mode?: Mode;
  step?: number;
  scope?: Abstract<DesignObject> | null;
  layers?: {
    all: Abstract<DesignatorLayer>[];
    current: Abstract<DesignatorLayer>;
  };
  namedObjects?: {
    [uuid: UUID]: NamedObject;
  };
  current_selection?: AbstractDesignatorObject[];
  global_setting?: GlobalSetting;
  designObjects?: { [uuid: UUID]: DesignObject | TEMP_ABSTRACT };
  // editPointIndex?: number | null;
  // jointObjects?: { [uuid: UUID]: JointObject };
}

export type AbstractDesignatorObject = {
  uuid: UUID;
  instance_uuid?: UUID;
  parent_uuid?: UUID;
  ref_uuid?: UUID;
  type: string;
};

export interface NamedObject extends AbstractDesignatorObject {
  name: string;
}

export interface DrawContextProps {
  draw_context: DrawContextStateProps;
  setDrawContext: Dispatch<
    | DrawContextActionProps
    | ((state: DrawContextStateProps) => DrawContextActionProps)
  >;
  addNamedObject: <T extends NamedObject>(value: T) => void;
  getNamedObject: <T extends NamedObject>(value: Abstract<T>) => T | undefined;
  getOverriddenNamedObject: <T extends NamedObject>(
    value: Abstract<T>
  ) => T | undefined;
  getNamedObjectsByType: <T extends NamedObject>(
    type: T["type"]
  ) => { [key: string]: T };
  updateNamedObject: <T extends NamedObject>(
    target: Abstract<T>,
    value: Partial<T>
  ) => void;
  replaceNamedObject: <T extends NamedObject>(
    target: Abstract<T>,
    value: T
  ) => void;
  deleteNamedObject: <T extends NamedObject>(target: Abstract<T>) => void;
  [key: string]: any;
  getDesignObject: <T extends DesignObject>(
    value: Abstract<T>
  ) => T | undefined;
  getOverriddenDesignObject: <T extends DesignObject>(
    value: Abstract<T>
  ) => T | undefined;
  getDesignObjectsByType: <T extends DesignObject>(
    type: T["designObjectType"]
  ) => { [key: string]: T };
  updateDesignObject: <T extends DesignObject | LineObject>(
    target: Abstract<T>,
    value: Partial<T>
  ) => void;
}

export interface LineGeometry {
  points: number[];
}

export interface DesignObject extends AbstractDesignatorObject {
  name?: string;
  type: "DesignObject";
  designObjectType: string;
  layer: Abstract<DesignatorLayer>;
}

export interface LineObject extends DesignObject {
  designObjectType: "LineObject";
  geometry: LineGeometry;
  additionalParams: { [key: string]: any };
  details: Detail[];
  editable: boolean;
}

export interface ThicknessMaterialObject extends DesignObject {
  designObjectType: "ThicknessMaterial";
  thickness: number;
  material: Abstract<Material>;
  variable?: boolean;
}

export type AnyDesignObject = DesignObject | LineObject;

export type AnyInternalDetail = CrossSection;

export type AnyBoundaryDetail = EasyJoin;

export interface Detail {
  internalDetails: Abstract<AnyInternalDetail>[];
  boundaryDetails: Abstract<AnyBoundaryDetail>[];
}

type BoundaryDetailType = "EasyJoin";

export interface BoundaryDetail extends NamedObject {
  type: "BoundaryDetail";
  boundaryDetailType: BoundaryDetailType;
}

export interface EasyJoin extends BoundaryDetail {
  boundaryDetailType: "EasyJoin";
  priorities: Abstract<ThicknessMaterialObject>[];
}

export const DrawContext = createContext<DrawContextProps>(null);

export interface HatchType extends NamedObject {
  type: "HatchType";
  value: string;
  scale?: number;
}

export interface LineType extends NamedObject {
  type: "LineType";
  // 세부 정보?
}

export interface LineWeight extends NamedObject {
  type: "LineWeight";
  value: number;
}

export interface DesignatorLayer extends NamedObject {
  type: "DesignatorLayer";
  activated: boolean;
  locked?: boolean;
  linetype: Abstract<LineType>;
  lineweight: Abstract<LineWeight>;
  color: Abstract<ColorStyle>;
}

export interface ColorStyle extends NamedObject {
  type: "ColorStyle";
  value: string;
  cad_color?: string;
  ctb_color?: string;
  ctb_lineweight?: number;
}

export interface Material extends NamedObject {
  type: "Material";
  // thickness: number;
  hatch?: Abstract<HatchType>;
  hatchLayer?: Abstract<DesignatorLayer>;
  outlineLayer: Abstract<DesignatorLayer>;
  variable?: boolean;
}

type InternalDetailType = "CrossSection";

type SEBaseCoordinatesType = "center" | "relative";

interface SEBaseCoordinates {
  type: SEBaseCoordinatesType;
  materialIndex: number;
  value: number; // materialIndex 번째 material의 center에서의 offset value
}

export interface InternalDetail extends NamedObject {
  type: "InternalDetail";
  internalDetailType: InternalDetailType;
}

export interface CrossSection extends InternalDetail {
  internalDetailType: "CrossSection";
  // 밑에는 CrossSection specific
  baseCoordinates: SEBaseCoordinates;
  materials: AbstractArray<Abstract<ThicknessMaterialObject>>;
  thickness?: number;
}

const reduceDrawContext: Reducer<
  DrawContextStateProps,
  | DrawContextActionProps
  | ((state: DrawContextStateProps) => DrawContextActionProps)
> = (
  state: DrawContextStateProps,
  action:
    | DrawContextActionProps
    | ((state: DrawContextStateProps) => DrawContextActionProps)
) => {
  console.log({ ...state, action });
  if (typeof action === "function") {
    return { ...state, ...action(state) };
  }
  return { ...state, ...action };
};

export const DrawContextProvider = ({ children }) => {
  /* 샘플 컨텍스트 */

  const [draw_context, setDrawContext] = useReducer<
    Reducer<DrawContextStateProps, DrawContextActionProps>
  >(reduceDrawContext, getInitialDrawContext());

  const updateNamedObject = <T extends NamedObject>(
    target: Abstract<T>,
    value: Partial<T>
  ) => {
    const { uuid, type, ...to_update } = value;
    // console.log("triggered upd");
    setDrawContext({
      namedObjects: {
        ...draw_context.namedObjects,
        [target.uuid]: draw_context.namedObjects[target.uuid]
          ? { ...draw_context.namedObjects[target.uuid], ...to_update }
          : (value as unknown as NamedObject),
      },
    });
  };

  const replaceNamedObject = <T extends NamedObject>(
    target: Abstract<T>,
    value: T
  ) => {
    const { uuid, type, ...to_update } = value;
    // console.log("triggered rep");
    setDrawContext({
      namedObjects: {
        ...draw_context.namedObjects,
        [target.uuid]: { uuid: target.uuid, type: target.type, ...to_update },
      },
    });
  };

  const addNamedObject = (value: NamedObject) => {
    // console.log("triggered add");
    setDrawContext({
      namedObjects: { ...draw_context.namedObjects, [value.uuid]: value },
    });
  };

  const deleteNamedObject = <T extends NamedObject>(target: Abstract<T>) => {
    // console.log("triggered del");
    setDrawContext({
      namedObjects: Object.fromEntries(
        Object.entries(draw_context.namedObjects).filter(
          ([k, v]) => v.uuid !== target.uuid
        )
      ),
    });
  };

  const getNamedObject = <T extends NamedObject>(target: Abstract<T>) => {
    // console.log("triggered get");
    return draw_context.namedObjects[target?.uuid] as unknown as T | undefined;
  };

  const getOverriddenNamedObject = <T extends NamedObject>(
    value: Abstract<T>
  ) => {
    // console.log("triggered getoverr");
    let original = getNamedObject(value);
    return original
      ? _.mergeWith(_.cloneDeep(original), value, (v1, v2) => v2 ?? v1)
      : null;
  };

  const getNamedObjectsByType = <T extends NamedObject>(
    type: T["type"]
  ): { [key: string]: T } => {
    // console.log("triggered gettype");
    return Object.fromEntries(
      Object.entries(draw_context.namedObjects).filter(
        ([k, v]) => v.type === type
      ) as unknown as [string, T][]
    );
  };

  const getDesignObjectsByType = <T extends DesignObject>(
    type: T["designObjectType"]
  ): { [key: string]: T } => {
    // console.log("triggered gettype");
    return Object.fromEntries(
      Object.entries(draw_context.designObjects).filter(
        ([k, v]) => v.designObjectType === type
      ) as unknown as [string, T][]
    );
  };

  const updateDesignObject = (
    target: Abstract<DesignObject>,
    value: Partial<DesignObject>
  ) => {
    const { uuid, type, ...to_update } = value;
    setDrawContext({
      designObjects: {
        ...draw_context.designObjects,
        [target.uuid]: draw_context.designObjects[target.uuid]
          ? { ...draw_context.designObjects[target.uuid], ...to_update }
          : (value as DesignObject),
      },
    });
  };

  const replaceDesignObject = (
    target: Abstract<DesignObject>,
    value: DesignObject
  ) => {
    const { uuid, type, ...to_update } = value;
    setDrawContext({
      designObjects: {
        ...draw_context.designObjects,
        [target.uuid]: { uuid: target.uuid, type: target.type, ...to_update },
      },
    });
  };

  const addDesignObject = <T extends DesignObject | TEMP_ABSTRACT>(
    value: T
  ) => {
    setDrawContext({
      designObjects: { ...draw_context.designObjects, [value.uuid]: value },
    });
  };

  const deleteDesignObject = (value: Abstract<DesignObject>) => {
    setDrawContext({
      designObjects: Object.fromEntries(
        Object.entries(draw_context.designObjects).filter(
          ([k, v]) => v.uuid !== value.uuid
        )
      ),
    });
  };

  const getDesignObject = <T extends DesignObject>(value: Abstract<T>) => {
    return draw_context.designObjects[value.ref_uuid] as unknown as
      | T
      | undefined;
  };

  const getOverriddenDesignObject = <T extends DesignObject>(
    value: Abstract<T>
  ) => {
    let original = getDesignObject(value);
    return original ? { ...original, ...value } : null;
  };

  const abstractify = <T extends DesignObject>(obj: T): Abstract<T> => {
    if (obj === undefined) {
      return obj;
    }
    const { uuid, type } = obj;
    const new_uuid: UUID = getNewUUid();
    addDesignObject({
      uuid: new_uuid,
      type,
      ref_uuid: uuid,
    });
    return { uuid: new_uuid, ref_uuid: uuid, type } as Abstract<T>;
  };

  return (
    <DrawContext.Provider
      value={{
        draw_context,
        setDrawContext,
        updateNamedObject,
        replaceNamedObject,
        addNamedObject,
        deleteNamedObject,
        getNamedObject,
        getOverriddenNamedObject,
        getNamedObjectsByType,
        updateDesignObject,
        replaceDesignObject,
        addDesignObject,
        deleteDesignObject,
        getDesignObject,
        getOverriddenDesignObject,
        getDesignObjectsByType,
        abstractify,
      }}
    >
      {children}
    </DrawContext.Provider>
  );
};

const useDrawContext = () => {
  return useContext(DrawContext);
};

export default useDrawContext;
