import useDrawContext, {
  BoundaryDetail,
  DesignatorLayer,
  InternalDetail,
  LineObject,
} from "../hooks/useDrawContext";
import { abstractify } from "../utils/ObjectUtils";
import IconSymbol from "./IconSymbol";
import PropertyBlock from "./PropertyBlock";
import PropertyGroup from "./PropertyGroup";
import PropertyRow from "./PropertyRow";

import styles from "./PropertyGroupForDetail.module.scss";
import classNames from "classnames/bind";

import { Fragment } from "react/jsx-runtime";
import DropdownCommand from "./DropdownCommand";
import PropertyRowObject from "./PropertyRowObject";

import StandardSlabImg from "../imgs/standard_slab.png";

const cx = classNames.bind(styles);

const PropertyGroupForDetail = ({ data }: { data: LineObject }) => {
  const {
    getNamedObject,
    updateNamedObject,
    updateDesignObject,
    getNamedObjectsByType,
  } = useDrawContext();
  return (
    <PropertyGroup
      title="Detail"
      actions={[
        { icon_type: "assets", illust: "Assets" },
        data.details.length === 0
          ? {
              icon_type: "add",
              illust: "Add",
              onClick: () => {
                updateDesignObject(abstractify(data), {
                  details: [
                    {
                      internalDetails: [
                        abstractify(
                          Object.values(
                            getNamedObjectsByType<InternalDetail>(
                              "InternalDetail"
                            )
                          )?.[0]
                        ),
                      ],
                      boundaryDetails: [
                        abstractify(
                          Object.values(
                            getNamedObjectsByType<BoundaryDetail>(
                              "BoundaryDetail"
                            )
                          )?.[0]
                        ),
                      ],
                    },
                  ],
                });
              },
            }
          : {
              icon_type: "delete",
              illust: "Delete",
              onClick: () => {
                updateDesignObject(abstractify(data), {
                  details: [],
                });
              },
            },
      ]}
    >
      {data.details.map((each_detail, each_detail_idx) => (
        <Fragment key={each_detail_idx}>
          <PropertyRow>
            <PropertyBlock type="subgroup">Internal Detail</PropertyBlock>
            <PropertyBlock type="action" icon_type="assets" illust="Assets" />
            <PropertyBlock
              type="action"
              icon_type="add"
              illust="Add"
              use_dropdown
            >
              <DropdownCommand
                onClick={() => {
                  updateDesignObject(abstractify(data), {
                    details: [
                      {
                        internalDetails: [
                          ...data.details[0]?.internalDetails,
                          abstractify(
                            Object.values(
                              getNamedObjectsByType<InternalDetail>(
                                "InternalDetail"
                              )
                            )[0]
                          ),
                        ],
                        boundaryDetails: data.details[0]?.boundaryDetails,
                      },
                    ],
                  });
                }}
              >
                Add Cross Sections
              </DropdownCommand>
              <DropdownCommand>Add Repeated Objects</DropdownCommand>
              <DropdownCommand>Add Aligned Objects</DropdownCommand>
              <DropdownCommand>Add Free Internal Detail</DropdownCommand>
            </PropertyBlock>
          </PropertyRow>
          {each_detail.internalDetails
            .map((e) => ({ var: e, data: getNamedObject(e) }))
            .filter((e) => e.data.internalDetailType === "CrossSection")
            .map((cs, cs_idx) => (
              <Fragment key={cs_idx}>
                {cs_idx === 0 && (
                  <PropertyRow small>
                    <PropertyBlock type="property-name-group">
                      Cross Section
                    </PropertyBlock>
                  </PropertyRow>
                )}
                <PropertyRowObject
                  object={cs.var}
                  onDelete={() => {
                    updateDesignObject(abstractify(data), {
                      details: data.details.map((d) => ({
                        ...d,
                        internalDetails: d.internalDetails.filter(
                          (d, d_idx) => d_idx !== cs_idx
                        ),
                      })),
                    });
                  }}
                  onClick={(e) => {
                    updateDesignObject(abstractify(data), {
                      details: data.details.map((d) => ({
                        ...d,
                        internalDetails: d.internalDetails.map((d, d_idx) =>
                          d_idx !== cs_idx ? d : abstractify(e)
                        ),
                      })),
                    });
                  }}
                  onEdit={(e) => {
                    updateDesignObject(abstractify(data), {
                      details: data.details.map((d) => ({
                        ...d,
                        internalDetails: d.internalDetails.map((d, d_idx) =>
                          d_idx !== cs_idx ? d : e
                        ),
                      })),
                    });
                  }}
                >
                  <img src={StandardSlabImg} />
                </PropertyRowObject>
              </Fragment>
            ))}
          <PropertyRow>
            <PropertyBlock type="subgroup">Boundary Detail</PropertyBlock>
            <PropertyBlock type="action" icon_type="assets" illust="Assets" />
            <PropertyBlock
              type="action"
              icon_type="add"
              illust="Add"
              use_dropdown
            >
              <DropdownCommand
                onClick={() => {
                  updateDesignObject(abstractify(data), {
                    details: [
                      {
                        internalDetails: data.details[0]?.internalDetails,
                        boundaryDetails: [
                          ...data.details[0]?.boundaryDetails,
                          abstractify(
                            Object.values(
                              getNamedObjectsByType<BoundaryDetail>(
                                "BoundaryDetail"
                              )
                            )[0]
                          ),
                        ],
                      },
                    ],
                  });
                }}
              >
                Add Easy Join
              </DropdownCommand>
              <DropdownCommand>Add Aligned Objects</DropdownCommand>
              <DropdownCommand>Add Free Boundary Detail</DropdownCommand>
            </PropertyBlock>
          </PropertyRow>
          {each_detail.boundaryDetails
            .map((e) => getNamedObject(e))
            .filter((e) => e.boundaryDetailType === "EasyJoin")
            .map((ej, ej_idx) => (
              <Fragment key={ej_idx}>
                {ej_idx === 0 && (
                  <PropertyRow small>
                    <PropertyBlock type="property-name-group">
                      Easy Join
                    </PropertyBlock>
                  </PropertyRow>
                )}
                <PropertyRowObject
                  object={abstractify(ej)}
                  onDelete={() => {
                    updateDesignObject(abstractify(data), {
                      details: data.details.map((d) => ({
                        ...d,
                        boundaryDetails: d.boundaryDetails.filter(
                          (d, d_idx) => d_idx !== ej_idx
                        ),
                      })),
                    });
                  }}
                  onClick={(e) => {
                    updateDesignObject(abstractify(data), {
                      details: data.details.map((d) => ({
                        ...d,
                        boundaryDetails: d.boundaryDetails.map((d, d_idx) =>
                          d_idx !== ej_idx ? d : e
                        ),
                      })),
                    });
                  }}
                >
                  <IconSymbol type="easyjoin" />
                </PropertyRowObject>
              </Fragment>
            ))}
        </Fragment>
      ))}
    </PropertyGroup>
  );
};

export default PropertyGroupForDetail;
