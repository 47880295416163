import { useMemo, useRef } from "react";
import * as THREE from "three";
import useCanvasContext from "../components/CanvasContext";

interface BuildLineOptions {
  joinStyle?: "miter" | "bevel" | "round";
  closed?: boolean;
  miterLimit?: number; // miter limit 값 (기본: 4 정도)
}

export function buildLineGeometry(
  points: number[],
  strokeWidth: number,
  options?: BuildLineOptions
): THREE.BufferGeometry {
  const joinStyle = options?.joinStyle ?? "round";
  let closed = options?.closed ?? false;
  const miterLimit = options?.miterLimit ?? 4; // 디폴트 4

  // 1) 중복된 첫 점 / 마지막 점 제거
  if (points.length >= 4) {
    const xFirst = points[0];
    const yFirst = points[1];
    const xLast = points[points.length - 2];
    const yLast = points[points.length - 1];
    if (xFirst === xLast && yFirst === yLast) {
      points.splice(points.length - 2, 2);
      closed = true;
    }
  }

  const n = points.length / 2;
  if (n < 2) {
    return new THREE.BufferGeometry();
  }

  // 폐합 여부
  const segmentCount = closed ? n : n - 1;
  const halfWidth = strokeWidth * 0.5;

  const positions: number[] = [];

  // ─────────────────────────────────────────────
  // 보조 함수들
  // ─────────────────────────────────────────────

  function leftNormal(v: THREE.Vector2): THREE.Vector2 {
    return new THREE.Vector2(-v.y, v.x);
  }
  function rightNormal(v: THREE.Vector2): THREE.Vector2 {
    return new THREE.Vector2(v.y, -v.x);
  }

  // butt cap 세그먼트를 사각형(삼각형 2개)으로 생성
  function pushSegment(x1: number, y1: number, x2: number, y2: number) {
    const dx = x2 - x1;
    const dy = y2 - y1;
    const len = Math.sqrt(dx * dx + dy * dy);
    if (len === 0) return;

    // 노멀
    const nx = -dy / len;
    const ny = dx / len;

    // offset
    const lx1 = x1 + nx * halfWidth;
    const ly1 = y1 + ny * halfWidth;
    const rx1 = x1 - nx * halfWidth;
    const ry1 = y1 - ny * halfWidth;

    const lx2 = x2 + nx * halfWidth;
    const ly2 = y2 + ny * halfWidth;
    const rx2 = x2 - nx * halfWidth;
    const ry2 = y2 - ny * halfWidth;

    positions.push(
      lx1,
      ly1,
      0,
      rx1,
      ry1,
      0,
      lx2,
      ly2,
      0,

      lx2,
      ly2,
      0,
      rx1,
      ry1,
      0,
      rx2,
      ry2,
      0
    );
  }

  function pushTriangle(
    ax: number,
    ay: number,
    bx: number,
    by: number,
    cx: number,
    cy: number
  ) {
    positions.push(ax, ay, 0, bx, by, 0, cx, cy, 0);
  }

  /**
   * 두 직선(line) 교차를 구하는 유틸.
   * L1: A + t * D1
   * L2: B + s * D2
   * cross(D1, D2) = 0 이면 평행. 교차 불가 -> null
   */
  function intersectLines(
    A: THREE.Vector2,
    D1: THREE.Vector2,
    B: THREE.Vector2,
    D2: THREE.Vector2
  ): THREE.Vector2 | null {
    const cross = D1.x * D2.y - D1.y * D2.x;
    if (Math.abs(cross) < 1e-7) {
      return null; // 평행 또는 거의 평행
    }
    const dx = B.x - A.x;
    const dy = B.y - A.y;
    const t = (dx * D2.y - dy * D2.x) / cross;
    // 교차점
    return A.clone().add(D1.clone().multiplyScalar(t));
  }

  /**
   * 외각(바깥쪽) 기준으로 offset된 두 "직선"을 구해 miter 교차점을 찾는 로직
   *
   * @param corner 코너 점
   * @param prevDir 이전 세그먼트 방향 (정규화됨)
   * @param nextDir 다음 세그먼트 방향 (정규화됨)
   * @param outwardPrev 이전 세그먼트의 바깥쪽 노멀 (corner에서)
   * @param outwardNext 다음 세그먼트의 바깥쪽 노멀
   * @returns 교차점(없으면 null)
   */
  function computeMiterIntersection(
    corner: THREE.Vector2,
    prevDir: THREE.Vector2,
    nextDir: THREE.Vector2,
    outwardPrev: THREE.Vector2,
    outwardNext: THREE.Vector2
  ): THREE.Vector2 | null {
    // 이전 세그먼트 "offset 직선" = corner + outwardPrev + t * prevDir
    const A = corner.clone().add(outwardPrev);
    const D1 = prevDir.clone();

    // 다음 세그먼트 "offset 직선" = corner + outwardNext + s * nextDir
    const B = corner.clone().add(outwardNext);
    const D2 = nextDir.clone();

    return intersectLines(A, D1, B, D2);
  }

  /**
   * 코너 조인 구현 (miter limit 포함)
   */
  function pushJoin(
    cornerX: number,
    cornerY: number,
    prevDir: THREE.Vector2,
    nextDir: THREE.Vector2,
    joinStyle: "miter" | "bevel" | "round"
  ) {
    const corner = new THREE.Vector2(cornerX, cornerY);

    // cross>0 이면 nextDir이 prevDir 기준으로 '왼쪽'
    // 보통 cross>0이면 외각이 오른쪽, cross<0이면 외각이 왼쪽…
    const crossVal = prevDir.x * nextDir.y - prevDir.y * nextDir.x;

    let outwardPrev: THREE.Vector2;
    let outwardNext: THREE.Vector2;
    if (crossVal > 0) {
      // 외각은 '오른쪽' 측
      outwardPrev = rightNormal(prevDir).multiplyScalar(halfWidth);
      outwardNext = rightNormal(nextDir).multiplyScalar(halfWidth);
    } else {
      // 외각은 '왼쪽' 측
      outwardPrev = leftNormal(prevDir).multiplyScalar(halfWidth);
      outwardNext = leftNormal(nextDir).multiplyScalar(halfWidth);
    }

    // miter / bevel / round
    switch (joinStyle) {
      case "bevel": {
        // 코너를 삼각형 하나로 만들어 '깎아'냄
        const pA = corner.clone().add(outwardPrev);
        const pB = corner.clone().add(outwardNext);
        // (corner, pA, pB)
        pushTriangle(corner.x, corner.y, pA.x, pA.y, pB.x, pB.y);
        break;
      }

      case "round": {
        // round: pA->pB 구간을 작은 호(arc)로 이어붙임
        const segments = 8; // 호를 몇 개의 삼각형으로 근사할지
        const pA = corner.clone().add(outwardPrev);
        const pB = corner.clone().add(outwardNext);

        // 시작 각, 끝 각
        const angleA = Math.atan2(pA.y - corner.y, pA.x - corner.x);
        const angleB = Math.atan2(pB.y - corner.y, pB.x - corner.x);

        let delta = angleB - angleA;
        // 0 ~ 2π 범위로 보정
        while (delta < 0) delta += Math.PI * 2;
        while (delta > Math.PI * 2) delta -= Math.PI * 2;

        let prevX = pA.x;
        let prevY = pA.y;
        for (let i = 1; i <= segments; i++) {
          const t = i / segments;
          const theta = angleA + delta * t;
          const cx = corner.x + Math.cos(theta) * halfWidth;
          const cy = corner.y + Math.sin(theta) * halfWidth;

          // (corner, prev, current) 삼각형
          pushTriangle(corner.x, corner.y, prevX, prevY, cx, cy);

          prevX = cx;
          prevY = cy;
        }
        break;
      }

      case "miter":
      default: {
        // 1) 두 offset 직선의 교점(miterPoint) 계산
        const miterPoint = computeMiterIntersection(
          corner,
          prevDir,
          nextDir,
          outwardPrev,
          outwardNext
        );
        if (!miterPoint) {
          // 평행 또는 거의 평행해 miter 실패 -> bevel로 대체
          const pA = corner.clone().add(outwardPrev);
          const pB = corner.clone().add(outwardNext);
          pushTriangle(corner.x, corner.y, pA.x, pA.y, pB.x, pB.y);
          return;
        }

        // 2) miter limit 체크
        // miterPoint와 corner의 거리 / halfWidth > miterLimit 이면 bevel로
        const miterDist = miterPoint.distanceTo(corner);
        if (miterDist / halfWidth > miterLimit) {
          // bevel로 처리
          const pA = corner.clone().add(outwardPrev);
          const pB = corner.clone().add(outwardNext);
          pushTriangle(corner.x, corner.y, pA.x, pA.y, pB.x, pB.y);
        } else {
          // 정상 miter 처리
          const cornerA = corner.clone().add(outwardPrev);
          const cornerB = corner.clone().add(outwardNext);

          // 삼각형 2개로 연결
          // (corner, cornerA, miterPoint) + (corner, miterPoint, cornerB)
          pushTriangle(
            corner.x,
            corner.y,
            cornerA.x,
            cornerA.y,
            miterPoint.x,
            miterPoint.y
          );
          pushTriangle(
            corner.x,
            corner.y,
            miterPoint.x,
            miterPoint.y,
            cornerB.x,
            cornerB.y
          );
        }
        break;
      }
    }
  }

  // ─────────────────────────────────────────────
  // 메인 루프
  // ─────────────────────────────────────────────
  let prevDir: THREE.Vector2 | null = null;
  let firstDir: THREE.Vector2 | null = null;
  let firstCorner: THREE.Vector2 | null = null;

  for (let i = 0; i < segmentCount; i++) {
    const idxA = i;
    const idxB = (i + 1) % n; // closed면 마지막->0까지 연결

    const x1 = points[idxA * 2];
    const y1 = points[idxA * 2 + 1];
    const x2 = points[idxB * 2];
    const y2 = points[idxB * 2 + 1];

    // 선분
    pushSegment(x1, y1, x2, y2);

    // 방향벡터
    const dir = new THREE.Vector2(x2 - x1, y2 - y1).normalize();

    // 첫 세그먼트
    if (i === 0) {
      firstDir = dir.clone();
      firstCorner = new THREE.Vector2(x1, y1);
    }

    // 코너 처리
    if (i > 0 && prevDir) {
      // 코너 위치 = 이전 세그먼트 끝점 = (x1,y1)
      pushJoin(x1, y1, prevDir, dir, joinStyle);
    }

    prevDir = dir;
  }

  // 폐합 시, 마지막과 첫 세그먼트 코너 처리
  if (closed && prevDir && firstDir && firstCorner) {
    pushJoin(firstCorner.x, firstCorner.y, prevDir, firstDir, joinStyle);
  }

  // ─────────────────────────────────────────────
  // BufferGeometry 생성
  // ─────────────────────────────────────────────
  const geometry = new THREE.BufferGeometry();
  geometry.setAttribute(
    "position",
    new THREE.Float32BufferAttribute(positions, 3)
  );
  geometry.computeBoundingBox();
  geometry.computeBoundingSphere();
  return geometry;
}

// 3. R3fLine 컴포넌트
export const CustomLine = ({
  points, // [x1, y1, x2, y2, ...]
  stroke, // 라인 색상 (CSS 컬러 or Three.Color 가능한 문자열)
  strokeWidth = 2,
  opacity = 1,
  strokeScaleEnabled = true,
  options = {},
  ...props
}: {
  points: number[];
  stroke: string;
  strokeWidth?: number;
  strokeScaleEnabled?: boolean;
  options?: BuildLineOptions;
  opacity?: number;
}) => {
  const meshRef = useRef<THREE.Mesh>(null!);
  const { canvasContext } = useCanvasContext();

  // CPU에서 라인 지오메트리를 구성
  const geometry = useMemo(() => {
    return buildLineGeometry(
      points,
      strokeScaleEnabled ? strokeWidth : strokeWidth / canvasContext.scale,
      options
    );
  }, [points, strokeWidth, canvasContext.scale, strokeScaleEnabled]);

  return (
    <mesh ref={meshRef} geometry={geometry} {...props}>
      <meshBasicMaterial
        color={stroke}
        side={THREE.DoubleSide}
        transparent={opacity < 1}
        opacity={opacity}
      />
    </mesh>
  );
};
