import useDrawContext, {
  BoundaryDetail,
  DesignatorLayer,
  InternalDetail,
  LineObject,
} from "../hooks/useDrawContext";
import {
  abstractify,
  getCorrectColor,
  getScaleDenominator,
} from "../utils/ObjectUtils";
import IconSymbol from "./IconSymbol";
import PropertyBlock from "./PropertyBlock";
import PropertyGroup from "./PropertyGroup";
import PropertyRow from "./PropertyRow";

import styles from "./PropertyGroupForBackground.module.scss";
import classNames from "classnames/bind";

import { Fragment } from "react/jsx-runtime";
import DropdownCommand from "./DropdownCommand";
import PropertyRowObject from "./PropertyRowObject";

import StandardSlabImg from "../imgs/standard_slab.png";

const cx = classNames.bind(styles);

const PropertyGroupForBackground = () => {
  const { draw_context, setDrawContext } = useDrawContext();
  return (
    <PropertyGroup
      title="Window Style"
      actions={[
        { icon_type: "assets", illust: "Assets" },
        { icon_type: "add", illust: "Add" },
      ]}
    >
      <PropertyRow>
        <PropertyBlock
          type="text-input-fill"
          name="Window Color"
          value={draw_context.global_setting.background_color.toUpperCase()}
          onBlur={(event) => {
            setDrawContext({
              global_setting: {
                ...draw_context.global_setting,
                background_color: getCorrectColor(
                  event.target?.value,
                  draw_context.global_setting.background_color
                ),
              },
            });
          }}
        ></PropertyBlock>
        <PropertyBlock
          type="action"
          icon_type="match"
          illust="Pick Color"
        ></PropertyBlock>
      </PropertyRow>
      <PropertyRow>
        <PropertyBlock
          type="text-selection-fill"
          name="Render Style"
          value={draw_context.global_setting.rendering_style}
          use_dropdown
        >
          <DropdownCommand
            onClick={(event) =>
              setDrawContext({
                global_setting: {
                  ...draw_context.global_setting,
                  rendering_style: "print",
                },
              })
            }
          >
            print style
          </DropdownCommand>
          <DropdownCommand
            onClick={(event) =>
              setDrawContext({
                global_setting: {
                  ...draw_context.global_setting,
                  rendering_style: "layer",
                },
              })
            }
          >
            layer style
          </DropdownCommand>
          <DropdownCommand
            onClick={(event) =>
              setDrawContext({
                global_setting: {
                  ...draw_context.global_setting,
                  rendering_style: "uniform",
                },
              })
            }
          >
            uniform style
          </DropdownCommand>
        </PropertyBlock>
      </PropertyRow>
      {draw_context.global_setting.rendering_style === "print" && (
        <PropertyRow>
          <PropertyBlock
            type="text-input-fill"
            name="Scale"
            value={"1/" + draw_context.global_setting.scale_denominator}
            onBlur={(event) => {
              setDrawContext({
                global_setting: {
                  ...draw_context.global_setting,
                  scale_denominator: getScaleDenominator(
                    event.target?.value,
                    draw_context.global_setting.scale_denominator
                  ),
                },
              });
            }}
          ></PropertyBlock>
        </PropertyRow>
      )}
    </PropertyGroup>
  );
};

export default PropertyGroupForBackground;
